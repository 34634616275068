<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters" v-if="!readOnly">
          <b-input-group class="mb-9">
            <CompactSuggestion
              size="sm"
              v-model="filterTeamId"
              ref="teamSuggestionProcessorRef"
              suggestion-processor="teamSuggestionProcessor"
              style="min-width: 30%"
              :placeholder="$t('PARAMETERS.TYPE_TEAM_NAME')"
            ></CompactSuggestion>

            <b-form-select v-model="filterUnit" :options="units" size="sm">
              <template v-slot:first>
                <option value="" disabled>
                  {{ $t("PARAMETERS.SELECT_UNIT") }}
                </option>
              </template>
            </b-form-select>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Search"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-delete-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import ApiService from "@/core/services/api.service";
import { mapState } from "vuex";
import CompactSuggestion from "../component/CompactSuggestion";

export default {
  name: "parameters",
  components: { CompactSuggestion },
  data() {
    return {
      fields: [
        {
          key: "id",
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "name",
          label: this.$t("PARAMETERS.NAME"),
          sortingKey: "el.name",
          sortable: true,
        },
        {
          key: "description",
          label: this.$t("PARAMETERS.DESCRIPTION"),
          sortingKey: "el.description",
          sortable: true,
        },
        {
          key: "code",
          label: this.$t("PARAMETERS.CODE"),
          sortingKey: "el.code",
          sortable: true,
        },
        {
          key: "unitId",
          label: this.$t("PARAMETERS.UNIT"),
          formatter: this.unitFormatter,
          sortingKey: "el.unit.id",
          sortable: true,
        },
        {
          key: "global",
          label: this.$t("PARAMETERS.GLOBAL"),
          formatter: Common.booleanFormatter,
          sortingKey: "el.global",
          sortable: true,
        },
      ],

      filterName: "",

      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/api/parameter",
        requestType: "GET",
        requestParams: { enrich: true, teamId: ApiService.teamData.value },
      },

      currentPage: 1, // page numbers indexed from 1
      perPage: 10,

      showTableOverlay: true,
      calculate: false,
      selectedItem: null,
      filterTeamId: null,

      filterUnit: "",
      units: [],

      unitsMap: {},
      unitRequest: {
        resource: "/platform/api/dictionary/com.gracelogic.nlg.core.model.Unit",
        requestType: "GET",
        requestParams: {},
      },
    };
  },

  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
    readOnly() {
      return !this.user.grants.includes("SYSTEM:ADMIN");
    },
  },

  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PARAMETERS") },
    ]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("ACTIONS.EDIT"),
        action: this.onEditAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.DELETE"),
        action: this.onDeleteAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
    ]);
  },

  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;

    // if admin
    if (!this.readOnly) {
      // query for all existing parameters
      this.getList.requestParams = { enrich: true };
    }

    this.loadUnits();
    this.loadList();
  },

  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    loadUnits: function () {
      this.$store
        .dispatch(API_REQUEST, this.unitRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.unitsMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.units.push({ value: value.id, text: value.nameLocalized });
          }
        })
        .catch(this.onError);
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    onCreateNewAction: function () {
      this.$router.push({ name: "parameter" });
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction: function () {
      this.$router.push({
        name: "parameter",
        params: { id: this.selectedItem.id },
      });
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete: function () {
      const del = {
        resource: "/api/parameter/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;

      this.loadList();
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },

    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;

      this.getList.requestParams["teamId"] = this.filterTeamId
        ? this.filterTeamId
        : null;
      this.getList.requestParams["unitId"] =
        this.filterUnit.length > 0 ? this.filterUnit : null;

      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["teamId"];
      this.filterTeamId = null;
      this.$refs.teamSuggestionProcessorRef.clearValue();

      delete this.getList.requestParams["unitId"];
      this.filterUnit = "";

      this.loadList();
    },

    unitFormatter: function (value) {
      if (value && value in this.unitsMap)
        return this.unitsMap[value].nameLocalized;

      return "";
    },
  },
};
</script>
